import {createSlice} from "@reduxjs/toolkit";

const initialState ={
    user : []
}

export const userSlice = createSlice({
    name:"users",
    initialState,
    reducers : {
        setUserInfo : (state,action)=>{
            state.user =[action.payload]
        }
    }
}) 

export const {setUserInfo} = userSlice.actions;
export default userSlice.reducer; 