import React from 'react';

export default function Box_1() {
  return (
    <div className='col-12 px-3 px-md-5 py-3 py-md-5 page4_box'>

      <h1>Galle Face Green</h1>
      <hr className='brown-line underline'/>
      <p>Take a leisurely stroll along Galle Face Green, a picturesque oceanfront promenade perfect for enjoying stunning sunsets, sampling local street food delicacies like isso wade (shrimp fritters), and flying kites amidst the sea breeze.</p>

    </div>
  );
}
