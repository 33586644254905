import {createSlice} from "@reduxjs/toolkit";

const initialState ={
    booking : []
}

export const bookingSlice = createSlice({
    name:"booking",
    initialState,
    reducers : {
        setBookingInfo : (state,action)=>{
            state.booking =[action.payload]
        }
    }
}) 

export const {setBookingInfo} = bookingSlice.actions;
export default bookingSlice.reducer; 