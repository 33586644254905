import React from 'react'
import Page14 from '../Page14'
import NavBarGuestInfoPage from '../../layouts/NavBarGuestInfoPage'



export default function ContinueBooking() {
  return (
    <div>
      <NavBarGuestInfoPage/>
      <Page14/>
    </div>
  )
}
