import {configureStore } from "@reduxjs/toolkit";
import  userSlice  from "./slices/userSlice";
import bookingSlice from "./slices/bookingSlice";



const store = configureStore({
    reducer:{
        userInfo:userSlice,
        bookingInfo:bookingSlice
    }
    
})

export default store;