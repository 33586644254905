import React, { useState } from "react";
import NavBarGuestinfoPage from "../layouts/NavBarGuestInfoPage";
import BackButton from "../layouts/BackButton";
import "./Privacy.css";

export default function Privacy() {
  return (
    <div>
        <div className="navbar_stic">
      <NavBarGuestinfoPage />
      </div>
      <div className="d-flex align-items-center justify-content-center px-2 px-lg-0">
      <div className="main_div col-12 col-lg-6">
        <BackButton />
        <div className="px-2">
        <div className="main_heading">
          <h1>Privacy Policy</h1>
          <p className="main_p">Effective Date: August 12th 2024</p>
        </div>

        <div className="subpart">
          <h2>1. Introduction</h2>
          <p>
            At RUJ Sri Lanka, we value your privacy and are committed to
            protecting your personal information. This Privacy Policy outlines
            how we collect, use, and safeguard the information you provide when
            renting an apartment from us.
          </p>
        </div>

        <div className="subpart">
          <h2>2. Information We Collect</h2>
          <p>We may collect the following types of personal information:</p>

          <h3>2.1 Personal Identification Information</h3>
          <p>
            Name, address, phone number, email address, date of birth, and other
            contact information.
          </p>

          <h3>2.2 Financial Information</h3>
          <p>
            Payment details, credit history, and other related financial
            information.{" "}
          </p>

          <h3>2.3 Identification Documents</h3>
          <p>
            Copies of identification documents such as a driver's license,
            passport, or national ID.
          </p>

          <h3>2.4 Rental History</h3>
          <p>
            Copies of identification documents such as a driver's license,
            passport, or national ID.
          </p>

          <h3>2.5 Communication Records</h3>
          <p>
            Copies of identification documents such as a driver's license,
            passport, or national ID.
          </p>

          <h3>2.6 Technical Information</h3>
          <p>
            Copies of identification documents such as a driver's license,
            passport, or national ID.
          </p>
        </div>

        <div className="subpart">
          <h2>
          3. How We Use Your Information 
          </h2>
          <p>We may collect the following types of personal information:</p>

          <h3>3.1 Processing Applications: </h3>
          <p>To evaluate and process your apartment rental application</p>

          <h3>3.2 Tenant Management </h3>
          <p>
            To manage your rental agreement, including billing and maintenance
            requests.
          </p>

          <h3>3.3 Communication</h3>
          <p>
            To contact you with important information related to your rental,
            such as lease renewals, policy updates, and emergency notices.
          </p>

          <h3>3.4 Legal Obligations</h3>
          <p>
            To comply with legal requirements, including reporting to government
            authorities when necessary.
          </p>

          <h3>3.5 Marketing </h3>
          <p>
            With your consent, to send you promotional materials and offers
            related to our services.{" "}
          </p>
        </div>

        <div className="subpart">
          <h2>4. How We Share Your Information</h2>
          <p>We may collect the following types of personal information:</p>

          <h3>4.1 With Service Providers</h3>
          <p>To evaluate and process your apartment rental application</p>

          <h3>4.2 Legal Requirements</h3>
          <p>
            When required by law, such as to comply with a subpoena, court
            order, or legal process.{" "}
          </p>

          <h3>4.3 With Your Consent </h3>
          <p>
            If you provide explicit consent for us to share your information
            with third parties for specific purposes.
          </p>
        </div>

        <div className="subpart">
          <h2>5. How We Protect Your Information</h2>
          <p>
            We take appropriate technical and organizational measures to protect
            your personal information against unauthorized access, disclosure,
            alteration, and destruction. However, please note that no method of
            transmission over the internet or electronic storage is 100% secure.{" "}
          </p>
        </div>

        <div className="subpart">
          <h2>6. Your Rights </h2>
          <p>
            You have the following rights regarding your personal information:
            Access: You can request a copy of the personal information we hold
            about you. Correction: You can request that we correct any
            inaccurate or incomplete information. Deletion: You can request that
            we delete your personal information, subject to legal obligations.
            Objection: You can object to the processing of your personal
            information in certain circumstances{" "}
          </p>
        </div>

        <div className="subpart">
          <h2>7. Cookies and Tracking Technologies </h2>
          <p>
            Our website may use cookies and similar tracking technologies to
            enhance your experience. You can control the use of cookies through
            your browser settings.{" "}
          </p>
        </div>

        <div className="subpart">
          <h2>8. Changes to This Privacy Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. Any changes
            will be posted on our website, and we will notify you of significant
            changes.
          </p>
        </div>

        <div className="subpart">
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            how your personal information is handled, please contact us at:
            contact@rujsrilanka.com{" "}
          </p>
        </div>

        <div className="email_div">
          <h4>RUJ Sri Lanka</h4>
          <a href="mailto:contact@rujsrilanka.com">contact<span>@rujsrilanka.com</span></a>
        </div>
      </div>
      </div>
      </div>
      <div className="d-flex align-items-center justify-content-center  bg-black privacy_footer_div">
        <p className="text-center mb-0">
          Copyright &copy; 2024 rujsrilanka.com. All rights reserved.
        </p>
      </div>
    </div>
  );
}
