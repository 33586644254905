import React from 'react';

export default function Box_3() {
  return (
    <div className='col-12 px-3 px-md-5 py-3 py-md-5 page4_box'>

      <h1>Garden City</h1>
      <hr className='brown-line underline'/>
      <p>Throughout the year, Colombo hosts a variety of cultural festivals and events celebrating its diverse heritage. From religious celebrations like Vesak and Diwali to music and dance festivals, there's always something exciting happening in the city.</p>
    </div>
  );
}
