import React from 'react';
import page10_1 from '../assets/page10_1.svg';
import page10_2 from '../assets/page10_2.svg';
import page10_3 from '../assets/page10_3.svg';
import page10_4 from '../assets/page10_4.svg';
import page10_5 from '../assets/page10_5.svg';
import page10_6 from '../assets/page10_6.svg';
import page10_7 from '../assets/page10_7.svg';
import page10_8 from '../assets/page10_8.svg';
import page10_9 from '../assets/page10_9.svg';
import page10_10 from '../assets/page10_10.svg';
import page10_11 from '../assets/page10_11.svg';
import page10_12 from '../assets/page10_12.svg';
import { IoCheckmark } from "react-icons/io5";

export default function Page10() {
  return (
    <div className='col-12 mx-0 mx-lg-1 px-3 px-md-5 pb-5' id='page10'>
<div className="_line">
      <h1>What’s available in the apartment</h1>
      </div>
<div/>
<div className='d-none d-md-block'>
      <div className='row page10_sub_div pb-2 pt-4'>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_1} className="img-fluid me-2" />
          <p className="mb-0">24/7 Security</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_2} className="img-fluid me-2" />
          <p className="mb-0">Surveillance</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_3} className="img-fluid me-2" />
          <p className="mb-0">Fully Equipped Kitchen</p>
        </div>
      </div>
      <hr className='d-none d-md-block'/>

      <div className='row page10_sub_div pb-2 pt-4'>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_4} className="img-fluid me-2" />
          <p className="mb-0">High Speed Internet</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_5} className="img-fluid me-2" />
          <p className="mb-0">Coffee Machine</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_6} className="img-fluid me-2" />
          <p className="mb-0">Washing Machine</p>
        </div>
      </div>
      <hr className='d-none d-md-block'/>

      <div className='row page10_sub_div pb-2 pt-4'>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_7} className="img-fluid me-2" />
          <p className="mb-0">Iron Coffee Machine</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_8} className="img-fluid me-2" />
          <p className="mb-0">Fridge</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_9} className="img-fluid me-2" />
          <p className="mb-0">Premium Toiletries</p>
        </div>
      </div>
      <hr className='d-none d-md-block'/>

      <div className='row page10_sub_div pb-2 pt-4'>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_10} className="img-fluid me-2" />
          <p className="mb-0">Bread Toaster</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_11} className="img-fluid me-2" />
          <p className="mb-0">Microwave</p>
        </div>
        <div className='col-md-4 d-flex align-items-center'>
          <img src={page10_12} className="img-fluid me-2" />
          <p className="mb-0">Juice Maker</p>
        </div>
      </div>
      <hr className='d-none d-md-block'/>
      </div>




      <div className='d-block d-md-none'>
  <div className='row page10_sub_div_mobile pb-2 pt-4'>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_1} className="img-fluid me-2" alt="24/7 Security" />
        <p className="mb-0">24/7 Security</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_2} className="img-fluid me-2" alt="Surveillance" />
        <p className="mb-0">Surveillance</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_3} className="img-fluid me-2" alt="Fully Equipped Kitchen" />
        <p className="mb-0">Fully Equipped Kitchen</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_4} className="img-fluid me-2" alt="High Speed Internet" />
        <p className="mb-0">High Speed Internet</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_5} className="img-fluid me-2" alt="Coffee Machine" />
        <p className="mb-0">Coffee Machine</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_6} className="img-fluid me-2" alt="Washing Machine" />
        <p className="mb-0">Washing Machine</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_7} className="img-fluid me-2" alt="Iron Coffee Machine" />
        <p className="mb-0">Iron Coffee Machine</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_8} className="img-fluid me-2" alt="Fridge" />
        <p className="mb-0">Fridge</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_9} className="img-fluid me-2" alt="Premium Toiletries" />
        <p className="mb-0">Premium Toiletries</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_10} className="img-fluid me-2" alt="Bread Toaster" />
        <p className="mb-0">Bread Toaster</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_11} className="img-fluid me-2" alt="Microwave" />
        <p className="mb-0">Microwave</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
    <div className='col-12 d-flex justify-content-between align-items-center'>
      <div className='d-flex align-items-center'>
        <img src={page10_12} className="img-fluid me-2" alt="Juice Maker" />
        <p className="mb-0">Juice Maker</p>
      </div>
      <IoCheckmark className='tick_mark' />
    </div>
  </div>
</div>

    </div>
  );
}
