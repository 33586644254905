import React from 'react';

export default function Box_2() {
  return (
    <div className='col-12 px-3 px-md-5 py-3 py-md-5 page4_box'>

      <h1>Cultural Festivals</h1>
      <hr className='brown-line underline'/>
      <p>Colombo boasts an impressive array of green spaces and parks, including Viharamahadevi Park (formerly known as Victoria Park), which offers a serene escape from the bustling city life.</p>

    </div>
  );
}
