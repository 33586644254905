import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa6";

export default function Page5() {
  const [answer1Visible, setAnswer1Visible] = useState(false);
  const [answer2Visible, setAnswer2Visible] = useState(false);
  const [answer3Visible, setAnswer3Visible] = useState(false);
  const [answer4Visible, setAnswer4Visible] = useState(false);
  const [answer5Visible, setAnswer5Visible] = useState(false);
  const [answer6Visible, setAnswer6Visible] = useState(false);
  const [answer7Visible, setAnswer7Visible] = useState(false);
  const [answer8Visible, setAnswer8Visible] = useState(false);
  const [answer9Visible, setAnswer9Visible] = useState(false);
  const [answer10Visible, setAnswer10Visible] = useState(false);
  const [answer11Visible, setAnswer11Visible] = useState(false);

  const toggleQuestion1 = () => setAnswer1Visible(!answer1Visible);
  const toggleQuestion2 = () => setAnswer2Visible(!answer2Visible);
  const toggleQuestion3 = () => setAnswer3Visible(!answer3Visible);
  const toggleQuestion4 = () => setAnswer4Visible(!answer4Visible);
  const toggleQuestion5 = () => setAnswer5Visible(!answer5Visible);
  const toggleQuestion6 = () => setAnswer6Visible(!answer6Visible);
  const toggleQuestion7 = () => setAnswer7Visible(!answer7Visible);
  const toggleQuestion8 = () => setAnswer8Visible(!answer8Visible);
  const toggleQuestion9 = () => setAnswer9Visible(!answer9Visible);
  const toggleQuestion10 = () => setAnswer10Visible(!answer10Visible);
  const toggleQuestion11 = () => setAnswer11Visible(!answer11Visible);

  return (
    <div className="col-12 page5_main_div py-5" >
      <div className="pb-5">
        <div className="col-12 d-flex align-items-center page2-container mb-5">
          <div className="page2_p">
            <p>
              <span>FAQs</span>
            </p>
          </div>
          <div className="flex-grow-1">
            <hr className="orange-line" />
          </div>
        </div>

        <div>
          <div onClick={toggleQuestion1} className="question">
            <span>What is included in the serviced accommodation?</span>
            <div className="d-flex align-items-end">
              {answer1Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer1Visible && (
            <div>
              <div className="answer col-11 col-md-7">
              <ul>
                <li>Furnishings: Fully furnished with essential furniture such as beds, sofas, dining tables, and chairs and decorative items to make the space feel more like home.</li>
                <li>Kitchen Facilities: Equipped kitchen with appliances like a refrigerator, stove, microwave, dishwasher, and basic cooking utensils. </li>
                <li>Utilities: Includes utility bills (water, electricity, gas) as part of the rental cost, although this can vary.</li>
                <li>Internet and TV: High-speed internet access and often cable or satellite television are included.</li>
                <li>Security: Secure entry systems and 24/7 security personnel and surveillance cameras.</li>
                <li>Maintenance: On-site maintenance or repair services for any issues that might arise during your stay.</li>
              </ul>
              </div>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion2} className="question col-12">
            <span className="col-11">
              How often are the accommodations cleaned?
            </span>
            <div className="d-flex align-items-end">
              {answer2Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer2Visible && (
            <div>
              <p className="answer col-11 col-md-7 ">
              Regular cleaning services, including changing of bed linens and towels, are provided. The frequency can vary from daily to weekly.
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion3} className="question">
            <span>Is Wi-Fi available?</span>
            <div className="d-flex align-items-end">
              {answer3Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer3Visible && (
            <div>
              <p className="answer col-11 col-md-7">
              High-speed internet access available.
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion4} className="question">
            <span>Are towels and toiletries provided?</span>
            <div className="d-flex align-items-end">
              {answer4Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer4Visible && (
            <div>
              <p className="answer col-11 col-md-7">
              Bed linens, towels, and basic toiletries (like soap and shampoo) are typically provided.
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion5} className="question">
            <span>Is there parking available?</span>
            <div className="d-flex align-items-end">
              {answer5Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer5Visible && (
            <div>
              <p className="answer col-11 col-md-7">
              Our serviced accommodations offer on-site parking, which might be included in the rental fee or available for an additional charge. This can range from a dedicated parking spot to a parking garage.
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion6} className="question">
            <span>What are the check-in and check-out times?</span>
            <div className="d-flex align-items-end">
              {answer6Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer6Visible && (
            <div>
              <div className="answer col-11 col-md-7">
                <ul>
                  <li>Check-in Time: Between 2:00 PM and 4:00 PM. This allows the property time to prepare the accommodation for new guests after the previous occupants check out.</li>
                  <li>Check-out Time: Check-out times are usually in the morning, generally between 11:00 AM and 12:00 PM. This timeframe gives the property time to clean and prepare the accommodation for the next guest.</li>
                </ul>
              </div>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion7} className="question">
            <span>Is there a minimum stay requirement?</span>
            <div className="d-flex align-items-end">
              {answer7Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer7Visible && (
            <div>
              <p className="answer col-11 col-md-7">
              Minimum stay – 02 nights.
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion8} className="question">
            <span>Can I bring pets?</span>
            <div className="d-flex align-items-end">
              {answer8Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer8Visible && (
            <div>
              <p className="answer col-11 col-md-7">
              No Pets Allowed- Our serviced accommodations do not permit pets due to concerns about allergies, cleanliness, or property damage.
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion9} className="question">
            <span>Is the accommodation wheelchair accessible?</span>
            <div className="d-flex align-items-end">
              {answer9Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer9Visible && (
            <div>
              <p className="answer col-11 col-md-7">
              Contact the property directly to confirm their wheelchair accessibility features and any specific accommodations we can provide. 
              </p>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion10} className="question">
            <span>What amenities are available nearby?</span>
            <div className="d-flex align-items-end">
              {answer10Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer10Visible && (
            <div>
              <div className="answer col-11 col-md-7">
                <ol>
                  <li>Restaurants and Cafés: Various dining options, from casual eateries to fine dining, and coffee shops or bakeries.</li>
                  <li>Grocery Stores and Markets: Supermarkets, convenience stores, or local markets for everyday necessities and fresh produce.</li>
                  <li>Public Transport: Access to buses and trains</li>
                  <li>Banks and ATMs: Financial services and cash withdrawal options.</li>
                  <li>Pharmacies and Medical Services: Local pharmacies, clinics, or hospitals for health needs.</li>
                  <li>Fitness Centers: Gyms or fitness clubs for workout routines.</li>
                  <li>Shopping Centers: Malls or retail outlets for shopping needs.</li>
                  <li>Parks and Recreational Areas: Nearby parks, walking trails, or recreational facilities for outdoor activities.</li>
                  <li>Cultural and Entertainment Venues: Theaters, museums, cinemas, or concert halls for entertainment and cultural experiences.</li>
                  <li>Post Offices: For mailing and shipping services.</li>
                  <li>Beauty and Personal Care: Salons, spas, or barber shops for grooming and relaxation.</li>
                  <li>Tourist Attractions: Local landmarks and tourist attractions.</li>
                  <li>Restaurants and Takeaways: Places to eat out or order food, which can be particularly useful if you prefer not to cook.</li>
                  
                </ol>
                <p>To get a clear picture of what’s available, it’s helpful to:</p>
                <ul>
                  <li>Ask the Property.</li>
                  <li>Use Maps and Apps.</li>
                  <li>Check Local Guides.</li>
                </ul>
              </div>
            </div>
          )}
          <hr />
        </div>

        <div>
          <div onClick={toggleQuestion11} className="question">
            <span>How can I make a reservation?</span>
            <div className="d-flex align-items-end">
              {answer11Visible ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {answer11Visible && (
            <div>
              <div className="answer col-11 col-md-7">
                <ul>
                  <li>Visit our website where you can check availability, view rates, and make a reservation directly.</li>
                  <li>You can call or email the property to inquire about availability and make a reservation. This method can also be useful for asking specific questions or requesting special arrangements.</li>
                </ul>
              </div>
            </div>
          )}
          <hr />
        </div>
      </div>
    </div>
  );
}
