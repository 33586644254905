import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import moment from "moment";
import { IoArrowBackSharp } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { FaPlus, FaMinus } from "react-icons/fa6";
import page14_1 from "../assets/page14_1.svg";
import users from "../assets/users.svg";
import mail from "../assets/mail.svg";
import check_circle from "../assets/check-circle.svg";
import { IoMdLock } from "react-icons/io";
import page1_1 from "../assets/page1_1.webp";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import BackButton from "../layouts/BackButton";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../slices/userSlice";
import { Modal, Button,Spinner  } from "react-bootstrap";

export default function Page14() {
  const dispatch = useDispatch();

  const [adults, setAdults] = useState(0);
  const [children, setChildren] = useState(0);
  const [dates, setDates] = useState({});
  const [disabledDates, setDisabledDates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  

  const oneNightCost = 22.4;
  const tax = 32.54;

  const fetchBookingData = async () => {
    try {
      const response = await axios.get(`https://www.rujsrilanka.com/api/index.php`);
      console.log("response", response);
      if (response.data.status === 1) {
        const bookings = response.data.data;
        if (bookings) {
          const dateRanges = bookings.map((booking) => ({
            startDate: moment(booking.start_date),
            endDate: moment(booking.end_date),
          }));

          setDisabledDates(dateRanges);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error("There was an error fetching the booking info!", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, []);

  const handleAdultsChange = (delta) => {
    setAdults((prev) => {
      const newAdults = prev + delta;
      if (newAdults >= 0 && newAdults + children <= 6) {
        return newAdults;
      }
      return prev;
    });
  };

  const handleChildrenChange = (delta) => {
    setChildren((prev) => {
      const newChildren = prev + delta;
      if (newChildren >= 0 && adults + newChildren <= 6) {
        return newChildren;
      }
      return prev;
    });
  };

  const keyRef = useRef();
  

  const handleApply = (event, picker) => {
    const startDate = picker.startDate;
    const endDate = picker.endDate;
    if (!isRangeDisabled(startDate, endDate)) {
      setDates({
        startDate,
        endDate,
      });
    } else {
      setShowModal(true);
      picker.element.val("");
    }
  };

  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  const isDateDisabled = (date) => {
    return disabledDates.some((range) =>
      date.isBetween(range.startDate, range.endDate, "day", "[]")
    );
  };

  const isRangeDisabled = (startDate, endDate) => {
    let isDisabled = false;
    for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
      if (isDateDisabled(m)) {
        isDisabled = true;
        break;
      }
    }
    return isDisabled;
  };

  const handleSubmit = () => {
    dispatch(setUserInfo({ dates, adults, children }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <div className="py-5 page14_main_div">
      <div className="col-12">
        <BackButton />
        <div className="px-3 py-3 page14_box1 m-2">
          <p className="section-title">Check-in info</p>
          <div className="d-flex justify-content-between align-items-center page_14_form_feild">
            <p className="mb-0">No. of nights</p>
            <div className="d-flex align-items-center justify-content-center page14_selected_date_range_text">
              {dates.startDate ? (
                <p className="m-0">
                  {dates.startDate.format("DD MMM")} -{" "}
                  {dates.endDate.format("DD MMM")}
                </p>
              ) : (
                <p className="m-0">Select Date Range</p>
              )}
              {dates.endDate ? (
                <p className="ms-3 mb-0 night">
                  {Math.round(
                    (dates.endDate - dates.startDate) / (1000 * 60 * 60 * 24)
                  )}{" "}
                  nights
                </p>
              ) : (
                <p className="ms-3 mb-0 night">0 nights</p>
              )}
              <div className="ms-2">
                {disabledDates.length > 0 && (
                  <DateRangePicker
                    ref={keyRef}
                    onApply={handleApply}
                    onCancel={handleCancel}
                    initialSettings={{
                      opens: "left",
                      showDropdowns: false,
                      showCustomRangeLabel: false,
                      showWeekNumbers: false,
                      autoUpdateInput: false,
                      
                      minDate: moment().startOf("day"),
                      isInvalidDate: isDateDisabled,
                    }}
                  >
                    <div className="user_round_box_page14 ms-2">
                      <MdModeEdit />
                    </div>
                  </DateRangePicker>
                )}
              </div>
            </div>
          </div>
          <hr className="col-12" />
          <div className="d-flex justify-content-between align-items-center page_14_form_feild">
            <p className="mb-0">No. of Adults</p>
            <div className="d-flex align-items-center">
              <div
                className="user_round_box_page14 ms-2"
                onClick={() => handleAdultsChange(-1)}
              >
                <FaMinus />
              </div>
              <p className="count">{adults}</p>
              <div
                className="user_round_box_page14 ms-2"
                onClick={() => handleAdultsChange(1)}
              >
                <FaPlus />
              </div>
            </div>
          </div>
          <hr className="col-12" />
          <div className="d-flex justify-content-between align-items-center page_14_form_feild">
            <p className="mb-0">No. of Children</p>
            <div className="d-flex align-items-center">
              <div
                className="user_round_box_page14 ms-2"
                onClick={() => handleChildrenChange(-1)}
              >
                <FaMinus />
              </div>
              <p className="count">{children}</p>
              <div
                className="user_round_box_page14 ms-2"
                onClick={() => handleChildrenChange(1)}
              >
                <FaPlus />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-block d-md-flex">
        <div className="col-12 col-md-6 py-3 py-md-0">
          <div className="px-3 py-3 page14_box1 m-2">
            <div className="col-6 d-flex align-items-center">
              <img src={page14_1} className="img-fluid me-2 " />
              <p className="mb-0 colombo_page14_heading">
                Colombo 07, Sri Lanka
              </p>
            </div>
            <p className="under_colombo_section_title pt-4">
              Serene Heights Colombo
            </p>

            <img src={page1_1} className="img-fluid me-2 rounded" />
            <p className="mb-1 page14_p_heading pt-3">
              <u>See apartment details</u>
            </p>

            <div className="col-10 d-flex align-items-center">
              <img src={users} className="img-fluid me-2 " />
              <p className="mb-1 page14_p1 pt-2">Max 6 guests</p>
            </div>
            <div className="col-10 d-flex align-items-center">
              <img src={mail} className="img-fluid me-2 " />
              <p className="mb-1 page14_p1 pt-2">Instant email confirmation</p>
            </div>
            <div className="col-10 d-flex align-items-center">
              <img src={check_circle} className="img-fluid me-2" />
              <p className="mb-0 page14_p1 pt-2 pb-3">
                Free cancellation until 05/29/2024, 00:00 (GMT +8.00)
              </p>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6 py-3 py-md-0">
          <div className="px-3 py-3 page14_box1 m-2 ">
            <div className="d-flex justify-content-between align-items-center page14_final_p">
              {dates.endDate ? (
                <p className="mb-0">
                  {Math.round(
                    (dates.endDate - dates.startDate) / (1000 * 60 * 60 * 24)
                  )}{" "}
                  nights
                </p>
              ) : (
                <p className="mb-0">0 nights</p>
              )}

              {dates.endDate ? (
                <p className="page14_final_p_amount">
                  £{" "}
                  {(
                    Math.round(
                      (dates.endDate - dates.startDate) / (1000 * 60 * 60 * 24)
                    ) * oneNightCost
                  ).toFixed(2)}
                </p>
              ) : (
                <p className="page14_final_p_amount">£ 0</p>
              )}
            </div>
            <div className="dotted-line col-12"></div>
            <div className="d-flex justify-content-between align-items-center mt-3 page14_final_p">
              <p>Taxes & fees</p>
              {dates.endDate ? (
                <p className="page14_final_p_amount">£ {tax}</p>
              ) : (
                <p className="page14_final_p_amount">£ 0</p>
              )}
            </div>

            <div className="dotted-line col-12"></div>

            <div className="d-flex justify-content-between align-items-center">
              <p className="payment_amount_p">Payment amount</p>

              {dates.endDate ? (
                <p className="amount">
                  £
                  {(
                    Math.round(
                      (dates.endDate - dates.startDate) / (1000 * 60 * 60 * 24)
                    ) *
                      oneNightCost +
                    tax
                  ).toFixed(2)}
                </p>
              ) : (
                <p className="amount">£ 0</p>
              )}
            </div>
          </div>
          <div className="px-2 pt-5 pb-2 pt-md-0 pb-md-0 mt-3">
            <Link to="pay" className="text-decoration-none">
              <button
                onClick={handleSubmit}
                className="col-12 page2_btn_Continue_to_Pay d-flex align-items-center justify-content-center"
                disabled={!dates.endDate}
              >
                <IoMdLock className="fs-5 me-2" /> Continue to Pay
              </button>
            </Link>
          </div>
          <div className="px-4">
            <p className="pt-4 page14_last_phrase">
              Cancellation policy may require guests to give notice regarding
              their booking at least fourteen days in advance of their booking
              start date. Our Account Managers will also confirm, and can
              reconfirm, any terms and conditions, including the relevant
              cancellation policy to guests throughout the reservation stage.
              Guests will also be made aware of how they can cancel a
              reservation.
            </p>
          </div>

          <div className="px-4 page14_last_phrase_under_link">
            <p className="privacy_policy_small_adjust">
              Read our <Link to="/privacy">RUJ’s cancellation policy</Link>
            </p>
          </div>
        </div>
      </div>

 {/* Modal */}
      <div
        className="modal fade"
        id="dateRangeModal"
        tabIndex="-1"
        aria-labelledby="dateRangeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="dateRangeModalLabel">
                Unavailable Dates
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">
              Selected date range contains unavailable dates. Please select a
              different range.
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleCloseModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>  
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Dates Unavailable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The dates you selected are unavailable. Please choose different dates.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> 
    </div>
  );
}